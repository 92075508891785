import React from "react";
import "./ErrorScreen.scss";

import Error from "../Images/icon-Failure.png";
import VenueNow from "../Images/logo-rectangle-venuenow.png";

function FiveErrorScreen({ backHome }) {
  return (
    <div className="error__base" onClick={backHome}>
      <div className="error__title">
        <h2 className="error__title__text">
          Internal Server Error - Tap to retry
        </h2>
      </div>
      <div className="error__image">
        <img className="error__image__logo" src={Error} alt="Error" />
      </div>
      <div className="error__footer">
        <img className="error__footer__logo" src={VenueNow} alt="VenueNow" />
      </div>
    </div>
  );
}

export default FiveErrorScreen;
